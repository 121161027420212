import React from "react";
import styled from "styled-components";
import { FlexColumn } from "../../../styles";
import DropdownButton from "./DropdownButton";
import ViewAllButton from "./ViewAllButton";

const DropdownWrapper = styled(FlexColumn)`
  position: absolute;
  align-items: start;
  margin-top: 30px;
  margin-left: 5%;
  justify-content: center;
  max-height: 600px;
  border-radius: 18px;
  z-index: 10;
  flex: 1;
  @media (min-width: 377px) and (max-width: 428px) {
    padding-left: 8px;
    padding-right: 8px;
  }
  @media (min-width: 429px) and (max-width: 1450px) {
    padding-left: 16px;
    padding-right: 16px;
  }
  @media (min-width: 1489px) {
    padding-left: 144px;
    padding-right: 144px;
    margin-left: 1%;
  }
`;

function MapButtonRow() {
  return (
    <>
      <DropdownWrapper>
        <DropdownButton />
      </DropdownWrapper>
      <ViewAllButton />
    </>
  );
}

export default MapButtonRow;
