import { Feature } from "ol";
import { Point } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Select } from "ol/interaction";
import { click } from "ol/events/condition";
import { Circle, Fill, Icon, Stroke, Style } from "ol/style";
import { fromLonLat } from "ol/proj";
import whiteMarker from "../../../assets/icons/markerWithDesign.png";
import { ProjectResponse } from "../map-setup-context/InitialMapValues";

const createIconStyle = (
  src: string,
  height: number,
  anchor: [number, number],
) => {
  return new Style({
    image: new Icon({
      anchor,
      src,
      height,
    }),
  });
};

const createPointStyle = (
  radius: number,
  fillColor: string | number[],
  strokeColor: string | number[],
  strokeWidth: number,
) => {
  return new Style({
    image: new Circle({
      radius,
      fill: new Fill({
        color: fillColor,
      }),
      stroke: new Stroke({
        color: strokeColor,
        width: strokeWidth,
      }),
    }),
  });
};

const getMarkerLayer = (
  projects: ProjectResponse[],
  setPopupInfo: (popupInfo: ProjectResponse) => void,
  setShowPopup: (showPopup: boolean) => void,
) => {
  const vectorSource = new VectorSource();

  const defaultPointStyle = createPointStyle(3, "black", "white", 0.5);

  const features = projects.map((project) => {
    const targetCenter = fromLonLat([
      project.centerCoordinates.lon,
      project.centerCoordinates.lat,
    ]);

    const iconFeature = new Feature({
      geometry: new Point(targetCenter),
      id: `marker-${project.id}`,
      project,
    });

    const iconStyle = createIconStyle(whiteMarker, 80, [0.5, 1]);

    iconFeature.setStyle([defaultPointStyle, iconStyle]);

    return iconFeature;
  });

  vectorSource.addFeatures(features);

  const vectorLayer = new VectorLayer({
    source: vectorSource,
    className: "vector-layer",
  });

  vectorLayer.set("data-testid", "vector-layer");

  const handleFeatureSelect = (selectedFeature: Feature | undefined) => {
    if (selectedFeature) {
      const project = selectedFeature.get("project") as ProjectResponse;
      const selectedIconStyle = createIconStyle(whiteMarker, 100, [0.15, 0.5]);
      const selectedPointStyle = createPointStyle(
        3,
        [64, 228, 171],
        [64, 228, 171],
        0,
      );

      selectedFeature.setStyle([selectedPointStyle, selectedIconStyle]);
      setPopupInfo(project);
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  };

  const selectClick = new Select({
    condition: click,
    layers: [vectorLayer],
  });

  selectClick.on("select", (event) => {
    const selectedFeature = event.selected[0];
    handleFeatureSelect(selectedFeature);
  });

  return {
    vectorLayer,
    selectClick,
  };
};

export default getMarkerLayer;
