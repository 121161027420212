import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FlexColumn, StyledH2 } from "../../styles";
import MapComponent from "./MapComponent";
import MapButtonRow from "./map-button-row";
import PopupComponent from "./PopupComponent";
import { useMapContext } from "./map-setup-context/MapContext";
import {
  ProjectResponse,
  defaultContext,
} from "./map-setup-context/InitialMapValues";
import ErrorComponent from "../common/ErrorComponent";

const CoBenefitsHeader = styled(StyledH2)`
  @media (max-width: 428px) {
    padding-top: 24px;
    font-size: 24px;
  }

  @media (min-width: 429px) {
    text-align: left;
    padding-top: 32px;
    padding-bottom: 32px;
    margin-left: 85px;
  }
`;

export const StyledWrapper = styled(FlexColumn)`
  height: auto;
  padding-left: 8px;
  margin-top: 32px;
  margin-bottom: 32px;
  padding-right: 8px;
  background-color: #f7f7f7;
  border-radius: 16px;
`;

export const Container = styled(FlexColumn)`
  flex-wrap: wrap;
  margin-top: 8px;
  margin-bottom: 64px;
  width: 100%;
  height: 600px;
  background-color: white;
  @media (max-width: 800px) {
    height: 750px;
    margin-bottom: 0;
    margin-top: 12px;
    margin-bottom: 12px;
    border-radius: 16px;
  }
`;

function Wrapper({ children }: { children: React.ReactNode }) {
  return (
    <StyledWrapper>
      <CoBenefitsHeader>Your Projects</CoBenefitsHeader>
      <Container>{children}</Container>
    </StyledWrapper>
  );
}

function ImpactMap({
  hasError,
  projects,
}: {
  hasError: boolean;
  projects: ProjectResponse[];
}) {
  const { popupInfo, showPopup, setProjects: setMapProjects } = useMapContext();
  const [popupCardInfo, setPopupCardInfo] = useState(defaultContext.popupInfo);

  useEffect(() => {
    if (projects) {
      setMapProjects(projects);
      setPopupCardInfo(popupInfo);
    }
  }, [projects, setMapProjects, popupInfo]);

  if (projects.length === 0 || hasError) {
    return (
      <Wrapper>
        <ErrorComponent message="Failed to load projects map." />
      </Wrapper>
    );
  }
  return (
    <Container>
      <MapButtonRow />
      <MapComponent />
      {showPopup && <PopupComponent project={popupCardInfo} />}
    </Container>
  );
}

export default ImpactMap;
