import image from "./sample_img.png";
import { ProjectResponse } from "../components/impact-map/map-setup-context/InitialMapValues";

export const justOneMockProject: ProjectResponse = {
  id: "64de08434d2c7a00463cfa2a",
  name: "Agroforestry - Smallholder deforestation Farmers, Chiapas and Oaxaca",
  description:
    "Run by our partner Bosques Amazonicos SAC (BAM) this project aims to reduce deforestation in over 300,000 hectares of Peruvian Amazon and reduce millions of Run by our partner Bosques Amazonicos SAC (BAM) this project aims to reduce deforestation in over 300,000 hectares of Peruvian Amazon and reduce millions of Run by our partner Bosques Amazonicos SAC (BAM) this project aims to reduce deforestation in over 300,000 hectares of Peruvian Amazon and reduce millions of",
  country: "KE",
  images: { default: "sample_img" },
  SDG: [1, 2, 3, 11, 12, 13, 14],
  websiteUrl: "projects/tist-farmers-kenya",
  centerCoordinates: { lat: 70, lon: 56 },
  zoomLevel: 8,
  outcomes: [
    {
      unit: "m2",
      value: 5,
      isUnitOfSale: false,
    },
    {
      unit: "Tonnes CO2",
      value: 12,
      isUnitOfSale: true,
    },
  ],
  interventions: [
    {
      action: "",
      ecosystem: "",
      biome: "Farmland",
    },
  ],
  unitData: {
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [55.9, 69.9],
            [55.9, 70.1],
            [56.1, 70.1],
            [56.1, 69.9],
            [55.9, 69.9],
          ],
        ],
      ],
    },
    unitCount: 5,
    clientUnitTotalArea: 100,
  },
};

const mockProjects: ProjectResponse[] = [
  {
    id: "64de08434d2c7a00463cfa2b",
    name: "Aha a biodiversity project!",
    description:
      "Run by our partner Bosques Amazonicos SAC (BAM) this project aims to reduce deforestation in over 300,000 hectares of Peruvian Amazon and reduce millions of Run by our partner Bosques Amazonicos SAC (BAM) this project aims to reduce deforestation in over 300,000 hectares of Peruvian Amazon and reduce millions of Run by our partner Bosques Amazonicos SAC (BAM) this project aims to reduce deforestation in over 300,000 hectares of Peruvian Amazon and reduce millions of",
    country: "KE",
    images: { default: "sample_img" },
    SDG: [1, 2, 3, 11, 12, 13, 14],
    websiteUrl: "projects/tist-farmers-kenya",
    centerCoordinates: { lat: 70, lon: 56 },
    zoomLevel: 8,
    outcomes: [
      {
        unit: "m2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "Biodiversity",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Farmland",
      },
    ],
    unitData: {
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [55.9, 69.9],
              [55.9, 70.1],
              [56.1, 70.1],
              [56.1, 69.9],
              [55.9, 69.9],
            ],
          ],
        ],
      },
      unitCount: 5,
      clientUnitTotalArea: 100,
    },
  },
  {
    id: "64de08434d2c7a00463cfa9b",
    name: "Aha another biodiversity project!",
    description: "Biodiversity fake projecto",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14],
    websiteUrl: "projects/tist-farmers-kenya",
    centerCoordinates: { lat: -36.225866, lon: -66.288542 },
    zoomLevel: 8,
    outcomes: [
      {
        unit: "m2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "Biodiversity",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Forest",
      },
    ],
    unitData: {
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-66.298, -36.215],
              [-66.278, -36.215],
              [-66.278, -36.235],
              [-66.298, -36.235],
              [-66.298, -36.215],
            ],
          ],
        ],
      },
      unitCount: 5,
      clientUnitTotalArea: 100,
    },
  },
  {
    id: "64de08434d2c7a00463cfa2c",
    name: "A WRONG coordinates Test",
    description: "just goes to earthly HQ, and doesn't have any SDGs :( ",
    country: "GB",
    images: { default: image },
    websiteUrl: "projects/tist-farmers-kenya",
    centerCoordinates: { lat: -92.25528, lon: 16.2586 },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "m2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "Biodiversity",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Forest",
      },
    ],
  },
  {
    id: "64de08434d2c7a00463cfa2a",
    name: "Agroforestry - Smallholder deforestationdeforestationdeforestation Farmers, Chiapas and Oaxaca.",
    description:
      "Run by our partner Bosques Amazonicos SAC (BAM) this project aims to reduce deforestation in over 300,000 hectares of Peruvian Amazon and reduce millions of Run by our partner Bosques Amazonicos SAC (BAM) this project aims to reduce deforestation in over 300,000 hectares of Peruvian Amazon and reduce millions of Run by our partner Bosques Amazonicos SAC (BAM) this project aims to reduce deforestation in over 300,000 hectares of Peruvian Amazon and reduce millions of",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14],
    websiteUrl: "projects/tist-farmers-kenya",
    centerCoordinates: { lat: 16.2586, lon: -92.25528 },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "m2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "Tonnes CO2",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Forest",
      },
    ],
  },
  {
    id: "64650c6bdd15c50018d3848e",
    name: "Agroforestry- TIST Kenya",
    description:
      "A chance to support local farmers in Kenya at the beginning of their tree planting journey under the TIST program.A chance to support local farmers in Kenya at the beginning of their tree planting journey under the TIST program.A chance to support local farmers in Kenya at the beginning of their tree planting journey under the TIST program.A chance to support local farmers in Kenya at the beginning of their tree planting journey under the TIST program.A chance to support local farmers in Kenya at the beginning of their tree planting journey under the TIST program.A chance to support local farmers in Kenya at the beginning of their tree planting journey under the TIST program.",
    country: "GB",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15],
    websiteUrl: "projects/tist-farmers-kenya",
    centerCoordinates: {
      lat: -0.034,
      lon: 37.129,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "m2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "Tonnes CO2",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Forest",
      },
    ],
  },
  {
    id: "64de0855f189da00ac141992",
    name: "Agroforestry, Upper Tana",
    description:
      "A chance to support local farmers in Kenya at the beginning of their tree planting journey under the TIST program.",
    country: "GB",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: -0.683333,
      lon: 37.15,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "m2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "Tonnes CO2",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Forest",
      },
    ],
  },
  {
    id: "646bcb4872411e0056166399",
    name: "Bosques Sostenibles - Valle Iruelas",
    description: "TEST",
    country: "KE",
    images: {
      default:
        "https://storage.googleapis.com/production-earthly-org-project-images/not-found.png",
    },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: 40.401,
      lon: -4.5818,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "m2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "Tonnes CO2",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Forest",
      },
    ],
  },
  {
    id: "646bc81aab3c9d0066abf668",
    name: "Brazil Nut Concessions in Madre de dios",
    description: "TEST",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: -11.07,
      lon: -70.05,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "m2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "Tonnes CO2",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Forest",
      },
    ],
  },
  {
    id: "646bc8faab3c9d0066abf669",
    name: "Charlotte's Wood",
    description: "TEST",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: 51.24,
      lon: -2.765,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "Tonnes CO2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "m3 kelp",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Forest",
      },
    ],
  },
  {
    id: "646bca2d72411e0056166397",
    name: "Culm Moor Woodland Reserve",
    description:
      "Culm Moor Nature Reserve shows what Creating Tomorrow’s Forests are all about - using their expertise to create diverse, native, thriving woodland as a home for UK wildlife with benefits for people. In this case, a beautiful woodland is being developed on an unused dairy farm located within the North Devon Biosphere Reserve. The woodland will connect into existing woodland fragments to create a contiguous native broadleaved forest where the veteran trees will nurture the growing saplings. The habitat is perfect to carve a space for local birds, pollinators, mammals, lichen and moss communities",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: 50.69,
      lon: -4.0691,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "Tonnes CO2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "m2",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Forest",
      },
    ],
  },
  {
    id: "646515bcdd15c50018d38494",
    name: "Degraded Forest Restoration, Mijares",
    description: "TEST",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: 40.29,
      lon: -4.83,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "m2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "Tonnes CO2",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Forest",
      },
    ],
  },
  {
    id: "64de0865f189da00ac141993",
    name: "Forest Adaptation- Luckaitz Valley",
    description: "TEST",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: 40.29,
      lon: -4.83,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "m2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "Tonnes CO2",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Forest",
      },
    ],
  },
  {
    id: "64a6c050886dad00420d19db",
    name: "Forest Protection, Manoa Farm",
    description:
      "The Manoa REDD+ Project is a groundbreaking project spanning an impressive 74,038.7 hectares in Brazil. The project has garnered global recognition for its exceptional forest management practices and commitment to nature conservation over nearly 30 years. The project encompasses several crucial actions to achieve its ambitious goals to prevent deforestation in a rapidly declining region in the Amazon such as forest patrolling, satellite surveillance for illegal logging, facilitating training programs for local skill development etc thus contributing to the region's overall progress. Altogether, the project avoids 279,290 tons of CO2e emissions per year, equating to 8,378,697 tons of CO2e over the 30-year project duration.",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: 40.29,
      lon: -4.83,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "m2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "Tonnes CO2",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Forest",
      },
    ],
  },
  {
    id: "64de08784d2c7a00463cfa2d",
    name: "Forest Restoration, Chiapas and Oaxaca",
    description: "TEST",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: 40.29,
      lon: -4.83,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "m2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "Tonnes CO2",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Forest",
      },
    ],
  },
  {
    id: "64de08854d2c7a00463cfa2e",
    name: "Forest Trends - One Million Trees ProjectAgroforestrySmallholderFarmers,ChiapasandOaxaca",
    description: "TEST",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: 40.29,
      lon: -4.83,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "m2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "Tonnes CO2",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Forest",
      },
    ],
  },
  {
    id: "64ef7a2b53303e0018d57861",
    name: "Highland Restoration - Northern Ethiopia",
    description: "TEST",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: 14.25,
      lon: 38.85,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "m2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "Tonnes CO2",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Farmland",
      },
    ],
  },
  {
    id: "6658829979e3b70018d41f5f",
    name: "Iford Estate Woodland Creation",
    description: "TEST",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: 50.887255,
      lon: -0.033615,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "m2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "Biodiversity",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Forest",
      },
    ],
  },
  {
    id: "6464a0b71fb8a100753ffa97",
    name: "Kasigau Corridor REDD+ Project",
    description: "TEST",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: -3.707356,
      lon: 38.754959,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "m3 kelp",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "m2",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Forest",
      },
    ],
  },
  {
    id: "64650b5a92f304001139ec1c",
    name: "Mai Ndombe",
    description: "TEST",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: -2.565,
      lon: 16.21,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "Tonnes CO2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "m2",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Ocean And Coast",
      },
    ],
  },
  {
    id: "6438298b4630d90019a44345",
    name: "Mangrove Planting, Maroalika",
    description: "TEST",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: -16,
      lon: 45.15,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "m2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "Tonnes CO2",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Grassland",
      },
    ],
  },
  {
    id: "64650eb7dd15c50018d3848f",
    name: "Mangrove Restoration Pakistan",
    description: "TEST",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: 24.6,
      lon: 68.07,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "m2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "Tonnes CO2",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Grassland",
      },
    ],
  },
  {
    id: "66696e6e8ac35300117c5aa9",
    name: "Native Woodland Restoration - Sofala, Mozambique",
    description:
      "The Kukumuty project in Mozambique aims to restore around 400 hectares (in the first instance) of Miombo woodland near Chibabava, as well as establish agroforestry nurseries and systems in the area. The Miombo woodlands are a tropical woodland system that cover 10% of Africa and are increasingly endangered due to rapid climatic changes and increased economic pressure. By restoring this crucial ecosystem, and developing agroforestry systems to reduce pressure on the native woodland, the Kukumuty project will effectively conserve and restore one of Africa’s most magnificent ecosystems. ",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: -20.29282,
      lon: 33.65516,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "m2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "Tonnes CO2",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Grassland",
      },
    ],
  },
  {
    id: "6464b6ec1fb8a100753ffa9b",
    name: "Peatland Protection & Restoration, Sumatra-Merang",
    description:
      "The Kukumuty project in Mozambique aims to restore around 400 hectares (in the first instance) of Miombo woodland near Chibabava, as well as establish agroforestry nurseries and systems in the area. The Miombo woodlands are a tropical woodland system that cover 10% of Africa and are increasingly endangered due to rapid climatic changes and increased economic pressure. By restoring this crucial ecosystem, and developing agroforestry systems to reduce pressure on the native woodland, the Kukumuty project will effectively conserve and restore one of Africa’s most magnificent ecosystems. ",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: -2.220571,
      lon: 104.467444,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "m2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "Tonnes CO2",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Ocean And Coast",
      },
    ],
  },
  {
    id: "646bcac372411e0056166398",
    name: "Planting Mangroves in Madagascar - Ankilahila",
    description:
      "The Kukumuty project in Mozambique aims to restore around 400 hectares (in the first instance) of Miombo woodland near Chibabava, as well as establish agroforestry nurseries and systems in the area. The Miombo woodlands are a tropical woodland system that cover 10% of Africa and are increasingly endangered due to rapid climatic changes and increased economic pressure. By restoring this crucial ecosystem, and developing agroforestry systems to reduce pressure on the native woodland, the Kukumuty project will effectively conserve and restore one of Africa’s most magnificent ecosystems. ",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: -15.579,
      lon: 46.497,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "m2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "Tonnes CO2",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Grassland",
      },
    ],
  },
  {
    id: "666961a17330430018ee83de",
    name: "Regenerative Farming - Indo-Gangetic Plains, India",
    description:
      "The project accelerates the adoption of sustainable agricultural practices to cut greenhouse gas emissions, restore degraded land, and boost soil health and crop yields. Using regenerative techniques like reduced tillage, Direct Seeding of Rice (DSR), crop residue management, crop rotation, and cover crops, it replaces harmful traditional methods. Launched in June 2019 in Punjab and Haryana, it now extends to maize-wheat, sugarcane, and cotton systems, incorporating pulses and mustards as cover crops. Over 51,000 farmers across 600,000 acres in 7 Indian states are already a part of the project. This initiative stands out for its contribution to the 12 Sustainable Development Goals, making a significant stride towards sustainable agriculture with far-reaching impacts on the environment and local livelihoods.",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: 26.961246,
      lon: 78.299561,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "Tonnes CO2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "m2",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Peatland",
      },
    ],
  },
  {
    id: "6464b4dca3776e0065d02e30",
    name: "Rimba Raya",
    description:
      "The project accelerates the adoption of sustainable agricultural practices to cut greenhouse gas emissions, restore degraded land, and boost soil health and crop yields. Using regenerative techniques like reduced tillage, Direct Seeding of Rice (DSR), crop residue management, crop rotation, and cover crops, it replaces harmful traditional methods. Launched in June 2019 in Punjab and Haryana, it now extends to maize-wheat, sugarcane, and cotton systems, incorporating pulses and mustards as cover crops. Over 51,000 farmers across 600,000 acres in 7 Indian states are already a part of the project. This initiative stands out for its contribution to the 12 Sustainable Development Goals, making a significant stride towards sustainable agriculture with far-reaching impacts on the environment and local livelihoods.",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: -3.055312,
      lon: 112.15356,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "m2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "m3 kelp",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Peatland",
      },
    ],
  },
  {
    id: "6464aa0fa3776e0065d02e2f",
    name: "Seaweed Farming Innovation, Cornwall",
    description:
      "The project accelerates the adoption of sustainable agricultural practices to cut greenhouse gas emissions, restore degraded land, and boost soil health and crop yields. Using regenerative techniques like reduced tillage, Direct Seeding of Rice (DSR), crop residue management, crop rotation, and cover crops, it replaces harmful traditional methods. Launched in June 2019 in Punjab and Haryana, it now extends to maize-wheat, sugarcane, and cotton systems, incorporating pulses and mustards as cover crops. Over 51,000 farmers across 600,000 acres in 7 Indian states are already a part of the project. This initiative stands out for its contribution to the 12 Sustainable Development Goals, making a significant stride towards sustainable agriculture with far-reaching impacts on the environment and local livelihoods.",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: 50.414804,
      lon: -3.722571,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "m3 kelp",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "m2",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Peatland",
      },
    ],
  },
  {
    id: "64de08a64d2c7a00463cfa30",
    name: "Tree Plantation, Usambara Mountains",
    description: "The project...",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: 50.414804,
      lon: -3.722571,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "m2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "Tonnes CO2",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Peatland",
      },
    ],
  },
  {
    id: "64651378dd15c50018d38493",
    name: "Tree Planting- TIST Kenya",
    description: "The project...",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: -0.034,
      lon: 37.129,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "Tonnes CO2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "m2",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Peatland",
      },
    ],
  },
  {
    id: "65afa75d40b5cb00125ddc7d",
    name: "Tree Sponsorship, Central Scotland",
    description: "The project...",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: 56.14904,
      lon: -3.858,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "m2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "Tonnes CO2",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Peatland",
      },
    ],
  },
  {
    id: "64649cfea3776e0065d02e2c",
    name: "Tropical Forest Protection, Keo Seima",
    description: "The project...",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: 12.335594,
      lon: 106.841901,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "m2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "Tonnes CO2",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Peatland",
      },
    ],
  },
  {
    id: "65afa5c92a9488001944c14b",
    name: "Biodiversity? Let's pretendf this is",
    description: "The project...",
    country: "KE",
    images: { default: image },
    SDG: [1, 2, 3, 11, 12, 13, 14, 15, 16],
    websiteUrl: "",
    centerCoordinates: {
      lat: 55.65356,
      lon: -4.68888,
    },
    zoomLevel: 4,
    outcomes: [
      {
        unit: "m2",
        value: 5,
        isUnitOfSale: false,
      },
      {
        unit: "Biodiversity",
        value: 12,
        isUnitOfSale: true,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "Peatland",
      },
    ],
    unitData: {
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [55.9, 69.9],
              [55.9, 70.1],
              [56.1, 70.1],
              [56.1, 69.9],
              [55.9, 69.9],
            ],
          ],
        ],
      },
      unitCount: 5,
      clientUnitTotalArea: 100,
    },
  },
];

export default mockProjects;
