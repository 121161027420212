import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import ImpactCardMobile from "./ImpactCardMobile";
import ImpactCard from "./ImpactCard";
import { FlexColumn, FlexRow, StyledH2, StyledH4 } from "../../styles";
import ErrorComponent from "../common/ErrorComponent";

const isVerifiedEstimatedFeatureEnabled = false;

const TopImpactListContainer = styled(FlexRow)`
  justify-content: center;
  padding-top: 32px;
  flex-wrap: wrap;
`;

const ImpactCardContainer = styled(FlexRow)`
  padding: 8px 8px;
`;

const ImpactHeader = styled(StyledH2)`
  text-align: left;
  padding-top: 64px;
  margin-left: 85px;
`;

const ImpactHeaderMobile = styled(StyledH4)`
  text-align: left;
  padding-top: 32px;
  padding-left: 8px;
`;

type ImpactType = {
  unit: string;
  value: number;
};

const formatValuestoLong = (impact: Array<ImpactType>) => {
  if (!impact) {
    return [];
  }
  return impact.reduce((acc: ImpactType[], singleImpact: ImpactType) => {
    if (singleImpact.value === 0) {
      return acc;
    }

    if (singleImpact.unit === "Tonnes CO2") {
      return [
        ...acc,
        {
          unit: singleImpact.unit,
          value: parseFloat(singleImpact.value.toFixed(2)),
        },
      ];
    }
    if (
      singleImpact.unit === "Tree(s)" ||
      singleImpact.unit === "m3 Kelp" ||
      singleImpact.unit === "m2"
    ) {
      return [
        ...acc,
        {
          unit: singleImpact.unit,
          value: Math.floor(singleImpact.value),
        },
      ];
    }
    return acc;
  }, []);
};

function Impact({
  mainImpactList,
  hasError,
}: {
  mainImpactList: Array<{
    unit: string;
    value: number;
  }>;
  hasError: boolean;
}) {
  const isTopImpact = (i: number) => {
    return i === 0;
  };

  const isSingleImpact = () => {
    return mainImpactList.length === 1;
  };

  const isMobile = useMediaQuery({ query: "(max-width: 428px" });

  return (
    <FlexColumn
      style={{ paddingLeft: "8px", paddingRight: "8px", marginBottom: "32px" }}
    >
      {isMobile ? (
        <ImpactHeaderMobile>Impact to date</ImpactHeaderMobile>
      ) : (
        <ImpactHeader>Impact to date</ImpactHeader>
      )}
      <TopImpactListContainer>
        {hasError ? (
          <ImpactCardContainer>
            <ErrorComponent message="Failed to load impact data." />
          </ImpactCardContainer>
        ) : (
          formatValuestoLong(mainImpactList)
            .sort((a, b) => b.value - a.value)
            .map((impact, i) => {
              if (isMobile) {
                return (
                  <ImpactCardContainer
                    key={impact.unit}
                    data-testid="impact-card-container"
                  >
                    <ImpactCardMobile
                      isVerifiedEstimatedFeatureEnabled={
                        isVerifiedEstimatedFeatureEnabled
                      }
                      type={isTopImpact(i) ? "large" : "small"}
                      isSingleImpact={isSingleImpact()}
                      volume={impact.value}
                      unit={impact.unit}
                      verified={60}
                      estimated={40}
                    />
                  </ImpactCardContainer>
                );
              }

              return (
                <ImpactCardContainer key={impact.unit}>
                  <ImpactCard
                    isVerifiedEstimatedFeatureEnabled={
                      isVerifiedEstimatedFeatureEnabled
                    }
                    type={isTopImpact(i) ? "large" : "small"}
                    isSingleImpact={isSingleImpact()}
                    volume={impact.value}
                    unit={impact.unit}
                    verified={60}
                    estimated={40}
                  />
                </ImpactCardContainer>
              );
            })
        )}
      </TopImpactListContainer>
    </FlexColumn>
  );
}

export default Impact;
