import React from "react";
import styled from "styled-components";
import { Button } from "../../../styles";
import { useMapContext } from "../map-setup-context/MapContext";
import downChevron from "../../../assets/icons/chevron-down.png";
import projectIcon from "../../../assets/icons/clipboard-list.png";
import FlyToButton from "./FlyToButton";
import { StyledMapTooltip } from "./ViewAllButton";

const Dropdown = styled(Button)`
  position: absolute;
  left: 10%;
  word-break: break-all;
  @media (max-width: 800px) {
    width: 135px;
    height: 27px;
    font-size: 0.8rem;
    left: 0px;
  }
`;
const DropdownMenuItems = styled.div`
  position: absolute;
  top: calc(100% + 23px);
  left: 10%;
  max-height: 520px;
  overflow: scroll;
  box-shadow: -5px 12px 16px rgba(0, 0, 0, 0.15);
  word-break: break-all;
  -ms-overflow-style: none;
  scrollbar-width: none;
  @media (max-width: 800px) {
    font-size: 0.8rem;
    left: -10%;
    top: calc(100% + 17px);
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Icon = styled.img`
  margin-right: 8px;
  width: 20px;
  height: 20px;
`;

function DropdownButton() {
  const { projects, showDropdownMenu, setShowDropdownMenu } = useMapContext();

  const handleDropdownClick = () => {
    setShowDropdownMenu(!showDropdownMenu);
  };

  return (
    <>
      <Dropdown
        data-tooltip-id="click-project"
        onClick={handleDropdownClick}
        data-testid="dropdown-button"
      >
        <StyledMapTooltip
          id="click-project"
          place="bottom"
          content="Select a project to visit on your map."
          opacity={1}
        />
        <Icon src={projectIcon} alt="projects-icon" />
        Project <Icon src={downChevron} alt="down chevron icon" />
      </Dropdown>
      {showDropdownMenu && (
        <DropdownMenuItems data-testid="dropdown-menu">
          {projects.map((project) => (
            <FlyToButton
              data-tooltip-id="click-project"
              key={project.id}
              project={project}
              data-testid="dropdown-project-button"
            />
          ))}
        </DropdownMenuItems>
      )}
    </>
  );
}

export default DropdownButton;
