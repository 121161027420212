import * as React from "react";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AxiosResponse, isAxiosError } from "axios";
import { useErrorBoundary } from "react-error-boundary";
import api from "./api";
import Impact from "./components/impact";
import CoBenefits from "./components/co-benefits";
import ImpactMap from "./components/impact-map";
import ProjectList from "./components/project";
import TransactionLedger from "./components/transaction-ledger";
import { ProjectResponse } from "./components/impact-map/map-setup-context/InitialMapValues";
import { TransactionsResponse } from "./components/transaction-ledger/types";
import ErrorComponent from "./components/common/ErrorComponent";
import { FlexColumn } from "./styles";

const Container = styled(FlexColumn)`
  flex: 1;

  @media (min-width: 377px) and (max-width: 428px) {
    padding-left: 8px;
    padding-right: 8px;
  }

  @media (min-width: 429px) and (max-width: 1488px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (min-width: 1489px) {
    padding-left: 144px;
    padding-right: 144px;
  }
`;

type ImpactType = {
  unit: string;
  value: number;
};

type ImpactResponse = {
  mainImpact: Array<ImpactType>;
  coBenefits: Array<ImpactType>;
};

function Body() {
  const { clientId } = useParams();
  const { showBoundary } = useErrorBoundary();

  const [impact, setImpact] = React.useState<ImpactResponse>({
    mainImpact: [],
    coBenefits: [],
  });

  const [projects, setProjects] = React.useState<ProjectResponse[]>([]);

  const [transactionLedgerList, setTransactionLedgerList] =
    React.useState<TransactionsResponse>([]);
  const [isLedgerVisible, setIsLedgerVisible] = useState(true);
  const [errors, setErrors] = useState({
    impact: false,
    projects: false,
    transactions: false,
    universal: false,
  });

  useEffect(() => {
    if (clientId) {
      api
        .getImpact(clientId)
        .then((res: AxiosResponse<ImpactResponse>) => {
          setImpact(res.data);
        })
        .catch(() => {
          setErrors((prevState) => ({ ...prevState, impact: true }));
        });

      api
        .getProjects(clientId)
        .then((res: AxiosResponse<ProjectResponse[]>) => {
          const sortedProjects = res.data.sort((a, b) =>
            a.name < b.name ? -1 : 1,
          );
          setProjects(sortedProjects);
        })
        .catch(() => {
          setErrors((prevState) => ({ ...prevState, projects: true }));
        });

      api
        .getTransactions(clientId)
        .then((res: AxiosResponse<TransactionsResponse>) => {
          setTransactionLedgerList(res.data);
          if (res.status === 200 && res.data.length === 0) {
            setIsLedgerVisible(false);
          }
        })
        .catch((error) => {
          if (isAxiosError(error)) {
            if (error.response?.status === 403) {
              setIsLedgerVisible(false);
            } else {
              setErrors((prevState) => ({ ...prevState, transactions: true }));
            }
          }
        });

      if (
        errors.impact &&
        (errors.transactions || !isLedgerVisible) &&
        errors.projects
      ) {
        setErrors((prevState) => ({ ...prevState, universal: true }));
        showBoundary(
          "Sorry, this page can’t be loaded right now. Our technical team has been automatically notified and will be looking into this.",
        );
      }
    }
  }, [
    clientId,
    errors.impact,
    errors.projects,
    errors.transactions,
    isLedgerVisible,
    showBoundary,
  ]);

  return (
    <Container>
      {!errors.universal && (
        <>
          {errors.impact ? (
            <ErrorComponent message="Failed to load impact and co-benefits data." />
          ) : (
            <>
              <Impact
                mainImpactList={impact.mainImpact}
                hasError={errors.impact}
              />
              <CoBenefits
                coBenefitList={impact.coBenefits}
                hasError={errors.impact}
              />
            </>
          )}
          {errors.projects ? (
            <ErrorComponent message="Failed to load Projects data." />
          ) : (
            <>
              <ImpactMap hasError={errors.projects} projects={projects} />
              <ProjectList hasError={errors.projects} projects={projects} />
            </>
          )}
          <TransactionLedger
            transactionLedgerList={transactionLedgerList}
            hasError={errors.transactions}
            isLedgerVisible={isLedgerVisible}
          />
        </>
      )}
    </Container>
  );
}

export default Body;
