import React, { useEffect } from "react";
import "ol/ol.css";
import styled from "styled-components";
import { useMapContext } from "./map-setup-context/MapContext";
import { FlexRow } from "../../styles";

const MapDisplay = styled(FlexRow)`
  border-radius: 58px;
  width: 100%;
  height: 600px;
  z-index: 8;
  @media (max-width: 800px) {
    height: 750px;
  }
`;

function MapComponent() {
  const { mapRef, dataId } = useMapContext();

  useEffect(() => {
    const currentMap = mapRef.current;

    if (currentMap) {
      currentMap.setTarget("map-container");
    }
  }, [mapRef]);

  return (
    <MapDisplay
      id="map-container"
      data-testid={`map-container-${dataId}`}
      className="map-container"
    />
  );
}

export default MapComponent;
