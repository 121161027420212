import { useRef, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import Map from "ol/Map";
import { useMediaQuery } from "react-responsive";
import {
  mapMobile,
  mapDesktop,
  MapContexts,
  ProjectResponse,
} from "./InitialMapValues";

export function useMapStates(defaultContext: MapContexts) {
  const [showMap, setShowMap] = useState(false);
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupInfo, setPopupInfo] = useState<ProjectResponse>(
    defaultContext.popupInfo,
  );
  const [dataId, setDataId] = useState("");

  return {
    showMap,
    setShowMap,
    showDropdownMenu,
    setShowDropdownMenu,
    showPopup,
    setShowPopup,
    popupInfo,
    setPopupInfo,
    dataId,
    setDataId,
  };
}

export function useClientAndMapSizeInfo() {
  const { clientId } = useParams<{ clientId: string }>();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const currentMap = useMemo(
    () => (isMobile ? mapMobile : mapDesktop),
    [isMobile],
  );
  const mapRef = useRef<Map>(currentMap);

  return {
    clientId,
    isMobile,
    mapRef,
    currentMap,
  };
}
