import { fromLonLat } from "ol/proj";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import { defaults as defaultControls } from "ol/control";

export const initialCenterCoordinates: [number, number] = [23.91, 16.2];
export const initialCoordMercator = fromLonLat(initialCenterCoordinates);
export const initialZoom = -10;
export const projectionForCenter = "EPSG:3857";

const url =
  "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
const satelliteLayer = new TileLayer({
  preload: Infinity,
  maxZoom: 17,
  source: new XYZ({
    url,
    attributions: `ESRI, ${url}`,
  }),
});

export const mapMobile = new Map({
  target: "map-container",
  layers: [satelliteLayer],
  controls: [],
  view: new View({
    center: initialCoordMercator,
    zoom: 4,
    maxZoom: 17,
    projection: projectionForCenter,
  }),
});

const desktopMapControls = defaultControls();

export const mapDesktop = new Map({
  target: "map-container",
  layers: [satelliteLayer],
  controls: desktopMapControls,
  view: new View({
    center: initialCoordMercator,
    zoom: initialZoom,
    maxZoom: 17,
    projection: projectionForCenter,
  }),
});

export type Coordinates = { lat: number; lon: number };
export type PolygonCoordinates = Array<number>;
export type Images = { default: string };

export type BiodiversityPolygon = {
  type: "MultiPolygon";
  coordinates: PolygonCoordinates[][][];
};

export type Intervention = {
  action: string;
  ecosystem: string;
  biome: string;
};

export type Outcomes = {
  unit: string;
  value: number;
  isUnitOfSale: boolean;
};

export type UnitData = {
  geometry: BiodiversityPolygon;
  unitCount: number;
  clientUnitTotalArea: number;
};

export type ProjectResponse = {
  id: string;
  name: string;
  description: string;
  country: string;
  websiteUrl: string;
  images: Images;
  SDG?: Array<number>;
  interventions: Array<Intervention>;
  outcomes?: Array<Outcomes>;
  centerCoordinates: Coordinates;
  zoomLevel: number;
  unitData?: UnitData;
};
export interface MapContexts {
  mapRef: React.RefObject<Map>;
  projects: ProjectResponse[];
  setProjects: (projects: ProjectResponse[]) => void;
  showMap: boolean;
  showDropdownMenu: boolean;
  setShowDropdownMenu: (show: boolean) => void;
  showPopup: boolean;
  setShowPopup: (show: boolean) => void;
  popupInfo: ProjectResponse;
  setPopupInfo: React.Dispatch<React.SetStateAction<ProjectResponse>>;
  isMobile: boolean;
  dataId: string;
}

export const defaultContext: MapContexts = {
  mapRef: { current: mapDesktop },
  projects: [],
  setProjects: () => {},
  showMap: false,
  showDropdownMenu: false,
  setShowDropdownMenu: () => {},
  showPopup: false,
  setShowPopup: () => {},
  popupInfo: {
    id: "",
    name: "",
    description: "",
    country: "",
    images: {
      default: "",
    },
    SDG: [],
    websiteUrl: "",
    centerCoordinates: { lat: 0, lon: 0 },
    zoomLevel: 8,
    outcomes: [
      {
        unit: "",
        value: 0,
        isUnitOfSale: false,
      },
    ],
    interventions: [
      {
        action: "",
        ecosystem: "",
        biome: "",
      },
    ],
  },
  setPopupInfo: () => {},
  isMobile: false,
  dataId: "",
};
