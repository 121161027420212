import React, { createContext, useContext, useMemo, useEffect } from "react";
import { Pointer } from "ol/interaction";
import {
  MapContexts,
  defaultContext,
  ProjectResponse,
} from "./InitialMapValues";
import getMarkerLayer from "../map-layers/getMarkerLayer";
import { useMapStates, useClientAndMapSizeInfo } from "./useMapStates";

const MapContext = createContext<MapContexts>(defaultContext);

export function MapProvider({ children }: { children: React.ReactNode }) {
  const { clientId, mapRef, isMobile, currentMap } = useClientAndMapSizeInfo();
  const [projects, setProjects] = React.useState<ProjectResponse[]>([]);
  const {
    showMap,
    setShowMap,
    showDropdownMenu,
    setShowDropdownMenu,
    showPopup,
    setShowPopup,
    popupInfo,
    setPopupInfo,
    dataId,
    setDataId,
  } = useMapStates(defaultContext);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setTarget();
    }
  }, [clientId, mapRef]);

  useEffect(() => {
    if (mapRef.current && projects.length > 0) {
      const { vectorLayer, selectClick } = getMarkerLayer(
        projects,
        setPopupInfo,
        setShowPopup,
      );
      const hoverInteraction = new Pointer({
        handleMoveEvent: (event) => {
          const { pixel } = event;
          const hit = currentMap.hasFeatureAtPixel(pixel);
          const targetElement = currentMap.getTargetElement();
          targetElement.style.cursor = hit ? "pointer" : "default";
        },
      });
      mapRef.current.addLayer(vectorLayer);
      mapRef.current.addInteraction(hoverInteraction);
      mapRef.current.addInteraction(selectClick);
      const dataIdToSet = "markers";
      setDataId(dataIdToSet);
      setShowMap(true);
    } else {
      setShowMap(false);
    }
  }, [
    projects,
    currentMap,
    mapRef,
    setPopupInfo,
    setShowPopup,
    setDataId,
    setShowMap,
  ]);

  const contextValue = useMemo(
    () => ({
      mapRef,
      projects,
      setProjects,
      showMap,
      showDropdownMenu,
      setShowDropdownMenu,
      showPopup,
      setShowPopup,
      popupInfo,
      setPopupInfo,
      isMobile,
      dataId,
    }),
    [
      projects,
      setProjects,
      showMap,
      showDropdownMenu,
      popupInfo,
      showPopup,
      isMobile,
      dataId,
      mapRef,
      setPopupInfo,
      setShowDropdownMenu,
      setShowPopup,
    ],
  );

  return (
    <MapContext.Provider value={contextValue}>{children}</MapContext.Provider>
  );
}

export default MapProvider;

export const useMapContext = () => {
  return useContext(MapContext);
};
