const mockImpact = {
  mainImpact: [
    {
      unit: "m3 Kelp",
      value: 639475,
    },
    {
      unit: "Tree(s)",
      value: 1000,
    },
    {
      unit: "m2",
      value: 1,
    },
    {
      unit: "Tonnes CO2",
      value: 100.1219048398,
    },
  ],
  coBenefits: [
    {
      unit: "Hours of Education",
      value: 7,
    },
    {
      unit: "Hours of Employment",
      value: 29.75,
    },
  ],
};

export default mockImpact;
